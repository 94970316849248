import { ReactNode } from 'react';

export const Layout = ({ children }: { children?: ReactNode }) => {
    return (
        <>
            <div className="d-flex flex-column flex-root">
                <div className="login login-4 wizard d-flex flex-column flex-lg-row flex-column-fluid">
                    <div className="login-container order-2 order-lg-1 d-flex flex-center flex-row-fluid px-7 pt-lg-0 pb-lg-0 pt-4 pb-6 bg-white">
                        {children}
                    </div>
                    <div className="login-aside order-1 order-lg-2 bgi-no-repeat bgi-position-x-right">
                        <h3
                            className="pl-lg-35 pb-lg-0 pl-10 py-16 m-0 d-flex justify-content-lg-start font-weight-boldest display5 display1-lg"
                            id="logo-mobile"
                        >
                            <img
                                className="logo-img text-center"
                                src="https://cdn.kargaseguros.com/karga-seguros/public/logos/logo-light.png"
                                alt="kargaseguros.com"
                                width="80%"
                                height="auto"
                            ></img>
                        </h3>
                    </div>
                </div>
            </div>
            {/* </div> */}
        </>
    );
};
