import { useEffect } from 'react';
import { useSplashScreen } from '../../hooks/useSplashScreen';

export const FallbackLoading = () => {
    const [setVisible] = useSplashScreen();

    useEffect(() => {
        setVisible(true);
        return () => {
            setVisible(false);
        };
    });

    return null;
};
