import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './styles/index.scss'; // Standard version

import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';

// styles
import '@fortawesome/fontawesome-free/css/all.min.css';
import './styles/plugins/keenthemes-icons/font/ki.css';
import './styles/plugins/flaticon/flaticon.css';
import './styles/plugins/flaticon2/flaticon.css';

ReactDOM.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
    document.getElementById('root'),
);

serviceWorkerRegistration.register();

reportWebVitals();
