import { useEffect, useState } from 'react';

export const useSplashScreen = () => {
    const [visible, setVisible] = useState(false);

    useEffect(() => {
        const splashScreen = document.getElementById('splash-screen');

        // Show SplashScreen
        if (splashScreen && visible) {
            splashScreen.classList.remove('hidden');

            return () => {
                splashScreen.classList.add('hidden');
            };
        }
    }, [visible]);

    return [setVisible];
};
