import { lazy, Suspense } from 'react';
import {
    BrowserRouter as Router,
    Redirect,
    Route,
    Switch,
} from 'react-router-dom';

import { FallbackLoading } from '../components/layout/FallbackLoading';
import { Layout } from '../components/layout/Layout';

const LoginPage = lazy(() => import('../pages/LoginPage'));
const ForgotPwdPage = lazy(() => import('../pages/ForgotPwdPage'));
const ResetPwdPage = lazy(() => import('../pages/ResetPwdPage'));
const VerifyEmailPage = lazy(() => import('../pages/VerifyEmailPage'));
const ValidateCredentialsPage = lazy(
    () => import('../pages/ValidateCredentialsPage'),
);

export const Routes = () => {
    return (
        <Router basename={'/auth'}>
            <Layout>
                <Suspense fallback={<FallbackLoading />}>
                    <Switch>
                        <Route exact path="/login" component={LoginPage} />
                        <Route
                            exact
                            path="/forgot-password"
                            component={ForgotPwdPage}
                        />
                        <Route
                            exact
                            path="/reset-password"
                            component={ResetPwdPage}
                        />
                        <Route
                            exact
                            path="/verify-email"
                            component={VerifyEmailPage}
                        />
                        <Route
                            exact
                            path="/validate-credentials"
                            component={ValidateCredentialsPage}
                        />
                        <Redirect to="/login" />
                    </Switch>
                </Suspense>
            </Layout>
        </Router>
    );
};
